import hourglass from './hourglass.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className="text-3xl font-bold">
        Hourglass Apps, LLC
      </h1>
      <img src={hourglass} className="logo" alt="logo" />
    </div>
  );
}

export default App;
